@import '../../config/settings.scss';
@import '../../config/colors.scss';

.root{
  background-color: color(grey, grey3);
  color: color(main, secondary);
  font-family: $font-family-headline;
  text-align:center;
  position:relative;
  padding:40px 0;
  background-position:center;
  background-repeat:no-repeat;
  background-size:cover;
  &:before{
    content: " ";
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:block;
    background-color:rgba(0,0,0,.7);
  }
  h2{
    position:relative;
    padding:40px 0;
    font-size: settings(fontSize, xl);
    line-height:1.2;
    border-top:settings(defaults, borderWidth) solid color(main,secondary);
    border-bottom:settings(defaults, borderWidth) solid color(main,secondary);
  }
}
