.root{
  background-size:cover;
  background-repeat: no-repeat;
  padding:70px 0;

  color:white;
  .formLabel{
    display:block;
  }
  .formInput{
    width:100%;
    background-color: #ddd;
  }
}
