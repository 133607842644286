.root{
  background-color: rgba(0,0,0,.7);
  position:fixed;
  z-index:10;
  width:100%;
  height:100%;
  top:0;
  left:0;
  display:flex;
  align-items: center;
  justify-content: center;
  img{
    width:100%;
    height:auto;
  }
  .close{
    position:absolute;
    right:10px;
    top:10px;
    color:white;
    width:50px;
    height:auto;
    cursor:pointer;
    background-color: rgba(0,0,0,.8);
    border-radius: 50%;
    padding: 4px;
    transform: scale(1);
    transition: .25s transform ease-out;
    &:hover{
      transform:scale(1.2);
    }
  }
  img{

  }
}
