@import '../../config/settings.scss';
@import '../../config/colors.scss';

.root{
  .photoCol{
    width:100%;
    height:100%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .textCol{
    padding:40px 0;
    margin:0;
    border-top:2px dashed color(main,secondary);
    border-bottom:2px dashed color(main,secondary);
    font-family: $font-family-headline;
    color:white;
  }
}
