@import '../../config/settings.scss';
@import '../../config/colors.scss';

.root{
  .galleryImage{
    transform:scale(1);
    transition: 0.25s transform ease-out;
    cursor:pointer;
    &:hover{
      transform:scale(1.1);
    }
  }
}

.galleryList{
  width:100%;
  overflow-x: hidden;
  .galleryListTitle{
    color:white;
  }
  .galleryListImg{
  }
}
