@import '../../config/settings.scss';
@import '../../config/colors.scss';

.root{
  position:relative;
  background-position:center;
  background-repeat:no-repeat;
  background-size:cover;
  margin:0 0 30px 0;
  padding:0;
  width:100%;
  height:600px;
  @media (max-width:768px){
    height:400px;
  }
  overflow:hidden;
  //padding:250px 0 150px 0;
  .mainHeadline{
    text-align: right;
    width:100%;
    color:color(main,primary);
    text-shadow: 2px 2px 10px rgba(0,0,0,.7);
  }
  .activityImg{
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    height:auto;
    @media (max-width:768px){
      width:105%;
      left:-5px
    }
  }
  .sliderDecoText{
    position:absolute;
    top:calc( 50% - 250px);
    width:100%;
    max-width:100%;
    overflow-x: hidden;
    overflow-y: hidden;
    color:rgba(0,0,0,.2);
    line-height: 1;
    font-family: $font-family-headline;
    font-size:500px;
    @media (max-width:768px){
      font-size:45vw;
      top:calc( 50% - 24vw);
    }
    transform: translateX(200px);
    transition: 6.0s transform ease-out;
  }
  .sliderContainer{
    padding-top:220px;
    overflow-x: hidden;
    overflow-y: hidden;
    max-width:100%;
    @media (max-width:768px) {
      padding-top:160px;
    }
    .sliderHeadline{
      position:relative;
      text-shadow: 2px 2px 10px rgba(0,0,0,.7);
      color:color(main,secondary);
      line-height: 1;
      font-size:settings(fontSize,xxl);
      @media (max-width:768px){
        font-size:15vw;
      }
      opacity:0;
      transform: translateX(100px);
      transition: 0.75s all ease-out;
      transition-delay: 0.7s;
    }
  }
  .navigation{
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    text-align:right;
    @media (max-width:768px) {
      bottom:20px;
    }
    .navDot{
      cursor:pointer;
      display:inline-block;
      width:20px;
      height:20px;
      background-color: rgba(255,255,255,.2);
      border:1px solid white;
      @media (max-width:768px) {
        background-color: rgba(255,50,50,.2);
        border-color:rgb(255,50,50);
      }
      margin-left:20px;
      border-radius: 50%;
      transition: 0.3s all ease-out;
      transform:scale(1);
      &:hover{
        transform:scale(1.3);
      }
      &.navDotActive{
        background-color:white;
      }
    }
  }
  .sliderShader{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: rgba(208,69,84,0);
    background: linear-gradient(0deg, rgba(255,255,255,1) 5%, rgba(255,255,255,0.3) 20%, rgba(255,255,255,0) 40%);
  }
  .sliderImg{
    position:absolute;
    width:100%;
    height:600px;
    top:0;
    left:0;
    opacity:0;
    transform: scale(1.2);
    transition:1.0s all ease-out;
    .sliderImgBg{
      //filter: grayscale(1);
      position:absolute;
      width:100%;
      height:100%;
      top:0;
      left:0;
      background-position:center;
      background-repeat:no-repeat;
      background-size:cover;
      &:before{
        display:block;
        content: " ";
        position:absolute;
        width:100%;
        height:100%;
        top:0;
        left:0;
        background-color: rgba(255,255,255,.5);
      }
    }
    &.slideActive{
      opacity:1;
      transform: scale(1);
      .sliderHeadline {
        opacity:1;
        transform: translateX(0);
      }
      .sliderDecoText{
        transform: translateX(0);
      }
    }
    &:before{
      content: " ";
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      display:block;
    }
  }
}
