@import '../config/colors.scss';
@import '../config/settings.scss';

.root{
  position:fixed;
  z-index:2;
  width:100%;
  top:0;left:0;
  color:white;
  font-size: settings(fontSize, md);
  background-color:transparent;
  transition: .3s background-color ease;
  .headerRow {
    margin-top:10px;
    display:flex;
    justify-content: space-between;
    width:100%;  
  }
  .loginBtn{
    margin:10px 0;
    padding:0;
    height:max-content;
    background-color:rgba(0,0,0,.25);
    border-color:white;
    color:white;
    &:hover{
      background-color:rgba(0,0,0,.7);
    }
  }
}

.headerContainer{
  display:flex;
  justify-content: space-between;
}

.logoWrap{
  position:relative;
  .logo{
    // position:absolute;
    // top:10px;
    // left: calc(50% - 65px);
    margin-top:10px;
    width:150px;
    height:auto;
    transform:scale(1);
    transition:.3s all ease;
    background-color: transparent;
    &:hover{
      transform:scale(1.1);
    }
  }
}
.menuToggleWrap{
  background-color:rgba(0,0,0,.25)!important;
  height:max-content;
}

.isScrolled{
  .logo{
    width:120px;
    top:5px;
    left: calc(50% - 50px);
    background-color:rgba(0,0,0,.5);
    //border-radius:50%;
    padding:10px;
  }
  .menuToggleWrap{
    background-color:rgba(0,0,0,.5)!important;
  }
  .loginBtn{
    background-color:rgba(0,0,0,.5);
  }
}
