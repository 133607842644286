@import '../config/colors.scss';
@import '../config/settings.scss';

.root{
  width:100%;
  height:100%;
  display:block;
  position:fixed;
  top:0;
  left:0;
  background-color: rgba(0,0,0,.7);
  z-index:1;
  transition:.25s all ease-out;
  pointer-events: none;
  opacity:0;
  .menuWrap{
    max-height:100vh;
    overflow-y: auto;
    padding-top:150px;
    padding-bottom:200px;

  }
  .menuItem, .childMenuItem{
    opacity:0;
    transform: scale(.5);
    transition: .25s all ease-out;
  }
  &.active{
    pointer-events: all;
    opacity:1;
    .menuItem, .childMenuItem {
      opacity:1;
      transform:scale(1);
    }
  }
  .menuItem{
    color:white;
    display:block;
    width:100%;
    text-align:center;
    font-family: $font-family-headline;
    font-size: settings(fontSize, lg);
    margin:20px 0;
    &.menuItemHasChildren{
      margin-bottom: 0;
    }
  }
  .childMenuItem{
    display:block;
    width:100%;
    text-align:center;
    color:white;
    font-size: settings(fontSize, md);
    margin:10px 0;
  }
}
